@font-face {
  font-family: 'Futura Book';
  src: url('./fonts/futura-book.otf') format('opentype');
}

@font-face {
  font-family: 'Outfit';
  src: url('./fonts/Outfit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Halvetica';
  src: url('./fonts/halvetica.ttf') format('truetype');
}

* {
  cursor: none;
}

body {
  font-family: "Outfit";
  background-color: black;
  cursor: none;
}

::-webkit-scrollbar-thumb {
  background-color: white !important;
}

::-webkit-scrollbar-track {
  fill: white;
}

::-webkit-scrollbar {
  background-color: transparent !important;
  width: 0.3rem;
  height: 0.3rem;
}


.projx-btn {
  background-color: transparent;
  color: white;
  transition: all ease-in-out 0.3s;
}

.projx-btn:hover {
  background-color: white;
  color: black;
}

.projx-btn-icon {
  transform: scale(1);
  transition: transform ease-in-out 0.3s;
}

.projx-btn-icon:hover {
  transition: transform ease-in-out 0.3s;
  transform: scale(1.1);
}

.canvas {
  cursor: default !important;
  /* to overwrite the PresentationControls hand*/
}

.loadingscreen {
  pointer-events: none;
  user-select: none;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease-in-out 1s;
}

.loadingscreen.loaded {
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 1s;
}

.logoLoadingScreen {
  pointer-events: none;
  user-select: none;
  opacity: 1;
  transition: all ease-in-out 1s;
}

.logoLoadingScreen.loaded {
  opacity: 0;
}

.textLoadingScreen {
  pointer-events: none;
  user-select: none;
  opacity: 1;
  position: relative;
  top: 2rem;

  color: white;
  transition: all ease-in-out 1s;
}

.textLoadingScreen.loaded {
  opacity: 0;
}

.blur {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.windowGold-phone {
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8);
  gap: 2rem;
  align-items: center;
  color: white;
  flex-direction: column;
  /* border: solid 1px white; */
}

.windowGold-phone.show {
  visibility: visible;
  /* transition: all ease-in-out 0.3s; */
  opacity: 1;
}


.windowGold {
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  gap: 2rem;
  align-items: center;
  color: white;
  flex-direction: column;
  /* border: solid 1px white; */
}

.windowGold.show {
  visibility: visible;
  /* transition: all ease-in-out 0.3s; */
  opacity: 1;
}


.planetPlaceholder {
  opacity: 0;
  transition: all ease-in-out 0.3s;
}


.planetPlaceholder.show {
  opacity: 1;
  transition: all ease-in-out 0.3s;
}





/* WAVE ANIMATION SOUND LINE */
.custom-shape-divider-top-1723580449 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1723580449 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 129px;
}

.custom-shape-divider-top-1723580449 .shape-fill {
  fill: #FFFFFF;
}


.icon {
  opacity: 0.7;
  transition: all ease-in-out 0.3s;
}

.icon:hover {
  opacity: 1;
  transition: all ease-in-out 0.3s;
}

.ok-btn {
  background-color: white;
  opacity: 0.7;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 15%;
  transition: all ease-in-out 0.3s;
}

.ok-btn:hover {
  width: 20%;
  opacity: 1;
  transition: all ease-in-out 0.3s;
}

.understand-cookies {
  background-color: black;
  color: white;
  transition: all ease-in-out 0.3s;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.understand-cookies:hover {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.next-btn {
  color: black;
  background-color: white;
  opacity: 0.5;
  transition: all ease-in-out 0.3s;
}

.next-btn:hover {
  opacity: 1;
  transition: all ease-in-out 0.3s;
}

.blurOnHover {
  filter: blur(0px);
  transition: all ease-in-out 0.3s;
}

.blurOnHover.active {
  filter: blur(3px);
  transition: all ease-in-out 0.3s;
}

.showTeamMember {
  opacity: 0;
  text-shadow: 0px 0px 5px black;
  transition: all ease-in-out 0.3s;
}

.showTeamMember.show {
  opacity: 1;
  transition: all ease-in-out 0.3s;
}

.btn-link{
  background-color: rgba(255, 255, 255, 0.1);
  transition: all ease-in-out 0.3s;
}

.btn-link:hover{
  background-color: rgba(255, 255, 255, 0.2);
}