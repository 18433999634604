@font-face {
  font-family: 'Futura Book';
  src: url('./fonts/futura-book.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Futura Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Futura Book';
}